import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/KcJCpQ0Bi_A">
    <SEO title="Church Rescue - Partnerships" />
  </Layout>
)

export default SermonPost
